import { useEffect, useState } from "react";
import "./App.css";

const movies = [
  {
    index: 0,
    name: "Superman: The Mechanical Monsters",
    length: 2606,
    keyword: "superman",
  },
  {
    index: 1,
    name: "Gulliver's Travels",
    length: 14710,
    keyword: "gulliver",
  },
];

function App() {
  const [message, setMessage] = useState("");
  const [moviePlaying, setMoviePlaying] = useState();

  const play = (movie) => {
    setMoviePlaying(movie);
    var timeout, timeout2, interval;
    clearTimeout(timeout);
    clearTimeout(timeout2);
    clearInterval(interval);

    setMessage("Your movie will start in 5 seconds...");

    timeout2 = setTimeout(() => {
      setMessage("Playing...");
    }, 5000);

    timeout = setTimeout(() => {
      var favicon_video_image_counter = 0;
      var favicon_video_icon_tag = document.querySelector("link[rel='icon']");
      var favicon_video_images = [];
      var favicon_video_loaded_images = [];

      for (var i = 0; i < movie.length; i++) {
        var zeros = "";
        if (movie.keyword === "superman") {
          if (i < 10) {
            zeros = "000";
          } else if (i < 100) {
            zeros = "00";
          } else if (i < 1000) {
            zeros = "0";
          }
        } else if (movie.keyword === "gulliver") {
          if (i < 10) {
            zeros = "0000";
          } else if (i < 100) {
            zeros = "000";
          } else if (i < 1000) {
            zeros = "00";
          } else if (i < 10000) {
            zeros = "0";
          }
        }
        favicon_video_images.push(
          `https://favicongenerator.s3.amazonaws.com/${movie.keyword}/${movie.keyword}` +
            zeros +
            i +
            ".png"
        );
      }

      async function favicon_video_to_data_url(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      }

      favicon_video_images.map((url, idx) => {
        setTimeout(() => {
          favicon_video_to_data_url(url, function (dataUrl) {
            favicon_video_loaded_images[idx] = dataUrl;
          });
        }, 20 * idx);
      });

      interval = setInterval(function () {
        if (favicon_video_images[favicon_video_image_counter]) {
          favicon_video_icon_tag.href =
            favicon_video_loaded_images[favicon_video_image_counter];
        }
        if (
          favicon_video_image_counter ===
          favicon_video_loaded_images.length - 1
        )
          favicon_video_image_counter = 0;
        else favicon_video_image_counter++;
      }, 166);
    }, 5000);
  };

  useEffect(() => {
    if (message === "Playing...") {
      document.getElementsByTagName("body")[0].classList = "dark transition";
    } else {
      document.getElementsByTagName("body")[0].classList = "";
    }
  }, [message]);

  return (
    <div className={message === "Playing..." ? "App dark" : "App"}>
      <div className="content">
        <img src="https://moviefavicon.com/og.png" alt="moviefavicon.com" />
        <h1>
          Watch entire movies in a Favicon
          <br />
          <div>*Does not work in Safari or on mobile</div>
        </h1>
        {movies.map((movie, idx) => {
          return (
            <div key={idx}>
              <div onClick={() => play(movie)} className="movie">
                <img src="https://moviefavicon.com/arrow.png" alt="play" />
                {movie.name}
              </div>
              <div className="movie-status">
                {movie === moviePlaying && message}
              </div>
            </div>
          );
        })}
      </div>
      <div className="video">
        Build your own animated favicons with{" "}
        <a href="https://faviconvideo.com/"> faviconvideo.com </a>
      </div>
    </div>
  );
}

export default App;
